import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Chevron from "../../assets/images/svg/chevron.svg";

import {
  container,
  imageContainer,
  title as titleClass,
  category as categoryClass,
  image,
  seeMore,
} from "./news-box.module.scss";
import Button from "../atoms/button";

interface INewsBoxProps {
  title: string;
  link: string;
  img: any;
}

const NewsBox = ({ title, link, img }: INewsBoxProps) => {
  const { alternativeText, localFile } = img || {};
  const gatsbyImage = getImage(localFile);
  const { t } = useTranslation();

  return (
    <Link to={`/${link}`}>
      <div className={container}>
        <div className={imageContainer}>
          {gatsbyImage && (
            <GatsbyImage
              alt={alternativeText}
              image={gatsbyImage}
              className={image}
              objectFit={"cover"}
              objectPosition={"top"}
            />
          )}
        </div>
        <h3 className={titleClass}>{title}</h3>
        <div className={seeMore}>
          <p> {t("news.more")} </p>
          <Chevron />
        </div>
      </div>
    </Link>
  );
};

export default NewsBox;
