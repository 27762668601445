import React, { useEffect } from "react";
import { graphql } from "gatsby";
import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import { useTranslation } from "react-i18next";
import NewsBox from "../components/molecules/news-box";
import AOS from "aos";
import "aos/dist/aos.css";
import { newsContainer } from "./news.module.scss";
import ReactMarkdown from "react-markdown";
import { IQueryAllResult } from "../models/query-all-result.model";
import { getNodes } from "../utils/get-nodes";

interface INewsProps {
  readonly data: {
    allStrapiNews: IQueryAllResult<any>;
  };
}

const News = ({ data }: INewsProps) => {
  const { allStrapiNews } = data;
  const news = getNodes(allStrapiNews);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ once: true });
  }, []);
  return (
    <MainLayout titleSeo="News" descriptionSeo="News">
      {" "}
      <Title Tag={"h1"} size={"large"}>
        {t("news.title")}
      </Title>
      <div className={newsContainer} data-aos="fade-left">
        {news.map((item, index) => {
          console.log(item);
          return (
            <NewsBox
              link={item.slug}
              title={item.title}
              img={item.thumbnail_photo}
              key={index}
            />
          );
        })}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiNews(
      sort: { fields: index, order: DESC }
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          id
          title
          slug
          index
          thumbnail_photo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`;

export default News;
